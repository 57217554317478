<template>
    <div v-if="!start">
        <div v-if="!winner" class="relative">
            <img :src="runimg" class="bgimgw">
            <button @click="startFn" class="bg-blue-700 hover:bg-blue-800 px-8 p-2 rounded absolute top-1/2 left-1/2 tform font-spantaran text-2xl">Start</button>
        </div>
        <div v-if="winner" class="">

            <div id="luckywinner" class="fixed top-0 left-0 w-screen h-screen"></div>
            <div class="flex flex-1 flex-col items-center gap-4">
                <h1 class="text-5xl">Congratulation <span class="text-blue-400 text-bold">{{winner.name}}</span></h1>
                <h2 class="text-4xl">{{FormatBib(winner.bib)}}</h2>
            </div>
        </div>
    </div>
    <div v-else class="flex flex-1 flex-col items-center gap-4">
        <h1 class="text-6xl">{{citem.name}}</h1>
        <h2 class="text-4xl">{{FormatBib(citem.bib)}}</h2>
        <button @click="stopFn" class="bg-blue-700 hover:bg-blue-800 px-8 p-2 rounded">Stop</button>
    </div>
</template>

<script>
import { Fireworks } from 'fireworks-js'
import runimg from '../../assets/run1.png';
export default {
    props: ['items'],
    data(){
        return{
            citem:null,
            cindex:0,
            timeout:null,
            start:false,
            winner:null,
            firework:null,
            runimg:runimg,
        }
    },
    mounted(){
        
    },
    beforeDestroy(){
        if(this.luckywinner) this.luckywinner.stop();
    },
    methods:{
        startFn(){
            this.start = true;
            this.roll();
        },
        stopFn(){
            this.start = false;
            clearTimeout(this.timeout);
            this.winner = this.citem;
            setTimeout(this.fireworkFN, 100);
            const nid = this.winner.nid;
            const bib = this.winner.bib;
            this.$http.post(process.env.VUE_APP_API_ROOT+'/winner',{nid,bib});
            //this.winner.nid
        },
        fireworkFN(){
            this.luckywinner = new Fireworks(document.querySelector('#luckywinner'),{
                    brightness: {
                        min: 50,
                        max: 80
                    },
                    speed: 10,
                    gravity: 2,
                    explotion: 10,
                    particles: 300
                })
            this.luckywinner.start()
        },
        roll(){
            clearTimeout(this.timeout);
            this.citem = this.items[this.cindex];
            if(this.items.length == (this.cindex + 1)) this.cindex = 0;
            else this.cindex += 1;
            setTimeout(this.roll, 50);
        },
        FormatBib(num) {
            let r = "" + num;
            while (r.length < 4) {
                r = "0" + r;
            }
            return r;
        }
    },
}
</script>
<style scoped>
.bgimgw{
    max-width: 100%;
    width: 900px;
}
.tform{
    transform: translate(-50%,-50%);
}
</style>