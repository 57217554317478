import { render, staticRenderFns } from "./roll.vue?vue&type=template&id=3a90547c&scoped=true&"
import script from "./roll.vue?vue&type=script&lang=js&"
export * from "./roll.vue?vue&type=script&lang=js&"
import style0 from "./roll.vue?vue&type=style&index=0&id=3a90547c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a90547c",
  null
  
)

export default component.exports