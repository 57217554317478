<template>
	<div class="lucky">
		<div
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10"
		>
			<h1 class="text-center text-4xl font-spantaran">Lucky Draw</h1>
			<div
				v-if="err"
				class="flex justify-center flex flex-col gap-4 mb-2"
			>
				<div class="flex justify-center">
					<div
						class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
						role="alert"
					>
						<span class="block sm:inline px-8">{{ err }}</span>
						<span
							class="absolute top-0 bottom-0 right-0 px-4 py-3"
							@click="err = null"
						>
							<svg
								class="fill-current h-6 w-6 text-red-500"
								role="button"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<title>Close</title>
								<path
									d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
								/>
							</svg>
						</span>
					</div>
				</div>
			</div>
			<div v-if="!ready" class="">
				<div class="flex justify-center flex flex-col gap-4">
					<div class="flex justify-center">
						<div class="flex gap-4">
							<input
								v-model="startdate"
								class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								type="date"
								placeholder="Start Date"
								:disabled="isLoading"
							/>
							<div
								class="flex gap-1 bg-white text-gray-900 border rounded"
							>
								<select
									v-model="starttime.min"
									name="minutes"
									class="shadow appearance-none py-1 px-3"
									:disabled="isLoading"
								>
									<option
										v-for="i in 24"
										:key="i"
										:value="i - 1"
										:selected="i - 1 == starttime.min"
									>
										{{ i - 1 }}
									</option>
								</select>
								<span class="text-xl">:</span>
								<select
									v-model="starttime.sec"
									name="minutes"
									class="shadow appearance-none py-1 px-3"
									:disabled="isLoading"
								>
									<option
										v-for="i in 60"
										:key="i"
										:value="i - 1"
										:selected="i - 1 == starttime.sec"
									>
										{{ i - 1 }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="flex justify-center">
						<div class="flex gap-4">
							<input
								v-model="enddate"
								class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
								type="date"
								placeholder="Start Date"
								:disabled="isLoading"
							/>
							<div
								class="flex gap-1 bg-white text-gray-900 border rounded"
								:class="isLoading ? 'disbalesel' : null"
							>
								<select
									v-model="endtime.min"
									name="minutes"
									class="shadow appearance-none py-1 px-3"
									:disabled="isLoading"
								>
									<option
										v-for="i in 24"
										:key="i"
										:value="i - 1"
										:selected="i - 1 == endtime.min"
									>
										{{ i - 1 }}
									</option>
								</select>
								<span class="text-xl">:</span>
								<select
									v-model="endtime.sec"
									name="minutes"
									class="shadow appearance-none py-1 px-3"
									:disabled="isLoading"
								>
									<option
										v-for="i in 60"
										:key="i"
										:value="i - 1"
										:selected="i - 1 == endtime.sec"
									>
										{{ i - 1 }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div v-if="isLoading" class="flex justify-center">
						<button
							class="border px-4 py-1 rounded bg-red-800 border-red-800 flex items-center"
						>
							<span>Loading</span>
							<svg
								class="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
							>
								<circle
									class="opacity-25"
									cx="12"
									cy="12"
									r="10"
									stroke="currentColor"
									stroke-width="4"
								></circle>
								<path
									class="opacity-75"
									fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								></path>
							</svg>
						</button>
					</div>
					<div v-if="!isLoading" class="flex justify-center mt-6">
						<button
							@click="readyld"
							class="border px-4 py-1 rounded bg-red-700 border-red-700 hover:bg-red-800"
						>
							Ready Lucky Draw
						</button>
					</div>
				</div>
			</div>
			<div v-if="ready" class="">
				<div class="flex justify-center">
					<div class="px-4 py-3 relative">
						<roll :items="data" />
					</div>
				</div>
			</div>
		</div>
		<p class="text-center text-gray-100 text-xs mt-9">
			&copy;{{ new Date().getFullYear() }} ADK Hospital. All rights
			reserved.
		</p>
	</div>
</template>

<script>
	import roll from "../components/Lucky/roll.vue";
	export default {
		name: "Home",
		watch: {},
		data() {
			return {
				isLoading: false,
				ready: false,
				startdate: null,
				starttime: {
					min: 0,
					sec: 0,
				},
				enddate: null,
				endtime: {
					min: 0,
					sec: 0,
				},
				startdatetime: null,
				enddatetime: null,
				data: [],
				lucky: null,
				cdisplay: null,
				err: null,
			};
		},
		mounted() {
			const currentdate = new Date();
			let mon = currentdate.getMonth() + 1;
			let dy = currentdate.getDate();
			if (mon < 10) mon = "0" + mon;
			if (dy < 10) dy = "0" + dy;
			const cdaystr = `${currentdate.getFullYear()}-${mon}-${dy}`;
			this.startdate = cdaystr;
			this.enddate = cdaystr;
		},
		beforeUnmount() {},
		methods: {
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[array[i], array[j]] = [array[j], array[i]];
				}
			},
			readyld() {
				this.isLoading = true;
				this.err = null;
				let sd = {};
				let ed = {};
				sd.year = this.startdate.slice(0, 4);
				sd.month = this.startdate.slice(5, 7);
				sd.day = this.startdate.slice(8, 10);
				ed.year = this.enddate.slice(0, 4);
				ed.month = this.enddate.slice(5, 7);
				ed.day = this.enddate.slice(8, 10);
				this.startdatetime = new Date(
					sd.year,
					sd.month - 1,
					sd.day,
					this.starttime.min,
					this.starttime.sec
				).getTime();
				this.enddatetime = new Date(
					ed.year,
					ed.month - 1,
					ed.day,
					this.endtime.min,
					this.endtime.sec
				).getTime();
				this.$http
					.get(
						process.env.VUE_APP_API_ROOT +
							`/luckylist?gt=${this.startdatetime}&lt=${this.enddatetime}`
					)
					.then(this.handledata)
					.catch(this.handleerr);
			},
			handleerr(err) {
				this.err = "Unknown error: " + err;
				this.isLoading = false;
			},
			handledata(res) {
				this.isLoading = false;
				if (res.data.meta.totalitems && res.data.meta.totalitems > 0) {
					const arr = res.data.items;
					this.shuffleArray(arr);
					this.data = arr;
					this.ready = true;
				} else this.err = "No data found";
			},
		},
		components: {
			roll,
		},
	}; //today = new Date(timeElapsed);
</script>

<style lang="scss">
	.disbalesel {
		user-select: none;
	}
</style>
